import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import "./assets/breakpoint.scss";
import "./App.css";
import { LoginRoute } from "./Routes/LoginRoute";
import { useDispatch, useSelector } from "react-redux";
import {
    saveAccessTokenAction,
} from "../src/Redux/Actions/actions";
import { MasterRoute, SQMEngineerRoute, SupplierRoute, CERoute, RDRoute, SQERoute, SPRRoute, OPRRoute, OtherRoute } from "./Routes/MasterRoute";
import Header from "./Components/Header/Header";
import Loader from "./Components/Loader/Loader";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import UnauthorizedAccess from "./Components/UnauthorizedAccess/UnauthorizedAccess";
/*import IdleTimer from "react-idle-timer";*/
import {
    authenticateUserBasedOnValidToken,
} from "./Redux/APIs/api_login";

import { useMsal } from '@azure/msal-react';
import { useAuth0 } from "@auth0/auth0-react";
export default function App() {
    const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

    const allDetails = useAuth0();
    const dispatch = useDispatch();
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const [isLoader, setIsLoader] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [unAuthText, setUnAuthText] = useState("");

    const { instance, accounts } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const [isAuthorized, setIsAuthorized] = useState(true);

    const handleClose = (e, reason) => {
        if (reason === "backdropClick") {
            return;
        }
        setDialogOpen(false);
    };

    function btoaSecure(value) {
        const { btoa } = require("abab");
        let currDate = new Date().toDateString();
        let valueModified = value + " " + currDate;
        let valueSecured = btoa(btoa(valueModified));
        return valueSecured;

    }

    const getToken = async () => {
        try {
            const accessToken = await getAccessTokenSilently();
            dispatch(saveAccessTokenAction(accessToken));
            console.log("accessToken", accessToken);
            authenticateUserWithGID(accessToken);
        } catch (error) {
            console.error('Error fetching access token:', error);
        }
    };

    function authenticateUserWithGID(accessToken) {
        setIsLoader(true);
        authenticateUserBasedOnValidToken(dispatch, accessToken)
            .then((response) => {
                if (response) {
                    console.log("app", response)
                    setUnAuthText("");
                    setIsLoader(false);
                    setIsAuthorized(false);
                } else {
                    setIsLoader(false);
                    setUnAuthText(response.responseMsg);
                    setIsAuthorized(false);
                    setDialogOpen(true);
                }
            })
            .catch((error) => {
                setIsLoader(false);
                setUnAuthText("Exception occured while autheniticating user.");
                setDialogOpen(true);
            });
    }

    async function getUserMetadata() {
        const domain = "prd-op.siemens.auth0app.com";

        try {
            const accessToken = await getAccessTokenSilently({
                authorizationParams: {
                    audience: `https://${domain}/api/v2/`,
                    scope: "read:current_user",
                },
            });
            console.log("accessToken", accessToken);
        } catch (e) {
            console.log(e.message);
        }
    }

    useEffect(() => {
        console.log("Userdetails", userDetails);
        if (!isLoading && isAuthenticated && !userDetails.userId) {
            getToken();
            setIsLoader(true);
        }
        else {
            setIsLoader(false);
        }
    }, [isLoading, isAuthenticated, isAuthorized, getAccessTokenSilently, user?.sub]);

    return (
        <BrowserRouter>
            {userDetails && userDetails?.roleId > 0 ? (
                <>
                    <div className="ssiat-master-block">
                        <Header>Header Component</Header>
                        <main className="main-component">{userDetails.roleId === 1 ? MasterRoute : userDetails.roleId === 2 ? SQMEngineerRoute : userDetails.roleId === 3 ? SupplierRoute : userDetails.roleId === 6 ? CERoute : userDetails.roleId === 7 ? RDRoute : userDetails.roleId === 8 ? SQERoute : userDetails.roleId === 9 ? SPRRoute : userDetails.roleId === 10 ? OPRRoute : <Loader />}</main>
                    </div>
                </>
            ) : (userDetails && userDetails?.userData?.respMsg?.length > 0) ? OtherRoute : (
                LoginRoute
            )}
            <>
                <Dialog
                    disableEscapeKeyDown
                    open={dialogOpen}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Authentication Error"}
                    </DialogTitle>
                    <DialogContent>
                        <UnauthorizedAccess />
                    </DialogContent>
                </Dialog>
            </>
            {isLoader === true ? <Loader /> : <></>}
        </BrowserRouter>
    );
}
